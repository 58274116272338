<template>
  <registeration-layout>
    <div class="custom-container">
      <div class="login-container">
        <div class="login-header">
          <router-link to="/">
            <img class="logo" src="@/assets/images/logo.svg" alt="" />
          </router-link>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <login-info />
          </div>
          <div class="col-lg-4">
            <div class="login-body">
              <h6 class="form-title">
                {{ $t("LOGIN_SIGN_IN") }}
              </h6>
              <div class="mb-3">
                <login :loading="loading" @handleLogin="Login($event)" />
                <div class="text-center forgot-password-container">
                  <router-link
                    to="/forgot-password"
                    class="forgot-password"
                    a-tag="routeForgotPassword"
                    >{{ $t("LOGIN_FORGET_PASSWORD") }}
                  </router-link>
                </div>
                <div class="create-new-account">
                  <p class="create-new">
                    {{ $t("LOGIN_CREATE_ACCOUNT") }}
                    <span class="d-block text-center">
                      <router-link to="/register" class="forgot-password" a-tag="routeCreateAccount"
                        >{{ $t("LOGIN_NEW_ACCOUNT") }}
                      </router-link>
                    </span>
                  </p>
                </div>
              </div>
              <!-- <div class="col-md-1">
            <h6 class="login-or mb-3">
              {{ $t("LOGIN_OR") }}
            </h6>
          </div>
          <div class="col-md-5 login-with">
            <h6>
              {{ $t("LOGIN_LOGIN_WITH") }}
            </h6>
            <div class="d-flex">
              <img class="social-icon" src="@/assets/images/apple-icon.svg" alt="" />
              <img
                class="social-icon"
                src="@/assets/images/google-icon.svg"
                alt=""
              />
            </div>
          </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </registeration-layout>
</template>

<script>
import Login from "@/components/Registeration/Login";
import LoginInfo from "@/components/Registeration/LoginInfo";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Login,
    LoginInfo,
  },

  computed: {
    ...mapGetters(["loading"]),
  },
  methods: {
    ...mapActions(["Login"]),
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
