<template>
  <div>
    <h5 class="title">
      {{ $t("LOGIN_BETA_VERSION") }}
    </h5>
    <h2 class="main-title">
      {{ $t("LOGIN_CHOOSE_CRYPTO") }}
      <span>
        {{ $t("LOGIN_YOU_TRAD") }}
      </span>
    </h2>
    <div class="login-info-container">
      <div v-for="(data, index) in loginData" :key="index">
        <div class="login-info">
          <img :src="data.image" alt="" />

          <div>
            <h2>
              {{ data.title }}
            </h2>
            <p>
              {{ data.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginData: [
        {
          image: require("@/assets/images/talented-icon.svg"),
          title: this.$t("LOGIN_TALENTED_TRADERS"),
          description: this.$t("LOGIN_VERIFIED"),
        },
        {
          image: require("@/assets/images/no-fees-icon.svg"),
          title: this.$t("LOGIN_NO_FEES"),
          description: this.$t("LOGIN_MAKE_MONEY"),
        },
        {
          image: require("@/assets/images/low-icon.svg"),
          title: this.$t("LOGIN_LOW_INVESTEMENT"),
          description: this.$t("LOGIN_INVEST_TRADER"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
